import axios from "axios";
export const sendMessage= async (blob,user,title_document,template_type,start,end)=>{
    try {
        const {data}=await axios.post('https://tel.asaturn.ir/api/handleApp',{
            document:blob,
            title:title_document,
            name:user.first_name,
            type:template_type,
            start,end,
            id:user.id
        })
    } catch (error) {
      throw new Error(error)

    }
}