<script setup>
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-alpine.min.css";
import "@/assets/ag.css";
import pdfMake from "pdfmake/build/pdfmake";
import getDocDefinition from "@/pdfExport/docDefinition";
pdfMake.fonts={
  sans:{
    normal:'https://panel.charismabroker.com/fonts/iran-yekan-400.ttf',
    bold:'https://panel.charismabroker.com/fonts/iran-yekan-400.ttf',
    italics:'https://panel.charismabroker.com/fonts/iran-yekan-400.ttf',
    bolditalics:'https://panel.charismabroker.com/fonts/iran-yekan-400.ttf',
  }
}
import {useMemoize} from '@vueuse/core'
import {AgGridVue} from "ag-grid-vue3";
import {MultiFilterModule} from "ag-grid-enterprise";
import {SetFilterModule} from "ag-grid-enterprise";
import {ClientSideRowModelModule, ModuleRegistry} from "ag-grid-community";
import {inject, ref} from "vue";
// import printDoc from "@/pdfExport/printDoc";
import {printParams} from "@/columns";
// import {printDoc} from "@/composable/usePdfMake";
import {sendMessage} from "@/composable/useSendMessage";
const emitter=inject('emitter')



function convertDate(string) {
  return string.replace(
      /[\u0660-\u0669\u06f0-\u06f9]/g,
      (c) => c.charCodeAt(0) & 0xf
  );
}

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MultiFilterModule,
  SetFilterModule,
]);


function printDoc(printParams, gridApi, columnApi,template_type,title,start,end,user) {
  sendLoader.value=true
  let c_start = convertDate(start)
  let c_end = convertDate(end)
  let date = c_start === c_end ? c_start : `${c_end}  الی  ${c_start}`
  let date_reverse = c_start === c_end ? c_start : `${c_start}  الی  ${c_end}`
  const docDefinition = getDocDefinition(printParams, gridApi, columnApi,template_type,title,date);
  // pdfMake.createPdf(docDefinition).download(`${title} - ${date}`);
  const title_document=`${title}  ${date_reverse}`
  const pdfDocGenerator=pdfMake.createPdf(docDefinition)
  pdfDocGenerator.getBase64((data) => {
    sendMessage(data, user,title_document,template_type,start,end).then((response) => {
      sendLoader.value=false
      emitter.emit("showSnackSuccess",'فایل با موفقیت ارسال شد')
    }).catch(e=>{
      sendLoader.value=false
      emitter.emit("showSnackError",'خطا در دریافت فایل')
    })

  });

}

const props = defineProps(['rows', 'type', 'template_column', 'start', 'end','user'])
const defaultColumnsDef = {
  sortable: true,
  editable: false,
  filter: true,
  floatingFilter: true,
  resizable: true,

};
const gridApi = ref(null)
const columnApi = ref(null)
const sendLoader=ref(false)
const onGridReady = (params) => {
  gridApi.value = params.api;
  columnApi.value = params.columnApi
};
const handleTitle = () => {
  switch (parseInt(props.type) - 1) {
    case 0:
      return "اطلاعات عرضه فیزیکی مورخ :";
    case 1:
      return "اطلاعات عرضه فرعی مورخ :";
    case 2:
      return "اطلاعات عرضه صادراتی مورخ :";
    case 3:
      return " آمار معاملات فیزیکی مورخ :";
    case 4:
      return " آمار معاملات فرعی مورخ :";
    case 5:
      return " آمار معاملات صادراتی مورخ :";

    default:
      break;
  }
};

const exportPdf = useMemoize(() => {
      printDoc(printParams, gridApi.value, columnApi.value,parseInt(props.type),
          handleTitle(), props.start,props.end,props.user,);
    }
)
// const exportExcel=()=>{
//   let c_start = convertDate(props.start)
//   let c_end = convertDate(props.end)
//   let date = c_start === c_end ? c_start : `${c_end}  الی  ${c_start}`
//   gridApi.value.exportDataAsExcel({
//     fileName:handleTitle()+"-"+date
//   })
// }


</script>

<template>
  <div class="">
    <div class="mb-2">
            <v-btn
                size="large"
                :disabled="props.rows.length<=0"
                :loading="sendLoader"
                prepend-icon="fa-brands fa-telegram" color="blue-accent-3" block @click="exportPdf">
            دریافت در تلگرام
            </v-btn>
<!--            <v-btn append-icon="fa fa-file-excel" color="green-darken-2" block @click="exportExcel">Export Excel</v-btn>-->
    </div>
    <div
        dir="rtl"
        class="ag-theme-alpine-dark"
    >
      <ag-grid-vue
          :rowData="props.rows"
          :columnDefs="props.template_column"
          style="height: 400px"
          :defaultColDef="defaultColumnsDef"
          :enableRtl="true"
          :pagination="true"
          @grid-ready="onGridReady"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>
