<script setup>
import {inject, onMounted, ref, watch} from "vue";
import moment from "jalali-moment";
import AgGrid from "@/components/AgGrid.vue";
import {UseFetchData} from "@/service/getData";
import {useColor} from "@/composable/useColor";
import {templates_a, templates_s_a, amarSaderati, amarFari, amarFiziki} from "@/columns";
import Energy from "@/components/Energy.vue";
import axios from "axios";
import SnackBar from "@/components/SnackBar.vue";
const handleTemplate = (type) => {
  switch (type) {
    case 1:
    case 2:
      return templates_a;

    case 3:
      return templates_s_a;

    case 4:
      return amarFiziki;

    case 5:
      return amarFari;

    case 6:
      return amarSaderati;

    default:
      return templates_a; // Add a default case for safety

  }
}
const tab = ref(null)
const start = ref(moment().locale("fa").format("YYYY/MM/DD"))
const end = ref(moment().locale("fa").format("YYYY/MM/DD"))
const select_item = ref(1)
const current_color = ref('amber')
const buttons_item=useColor()
const rows = ref([])
const loading = ref(true)
const loadCount=ref(true)
const template_column=ref(templates_a);
const showMessage=ref(false)
const message=ref(false)
const countModel=ref([0,0,0,0,0,0])

const user=ref({
  id:0,
  first_name:''
})
const tg = window.Telegram.WebApp;
user.value= tg.initDataUnsafe.user;
onMounted(() => {
  const tg = window.Telegram.WebApp;
  tg.expand();
});

const colors = ["amber-lighten-3", "red-lighten-3", "teal-lighten-1", "indigo-lighten-3", "purple-lighten-1", "blue-lighten-3"]
watch(select_item, async () => {
  current_color.value = buttons_item.value[select_item.value - 1]?.color
  template_column.value=handleTemplate(select_item.value)
  start.value=moment().locale("fa").format("YYYY/MM/DD")
  end.value=moment().locale("fa").format("YYYY/MM/DD")
  await fetchData()
})
const getAllCount=async ()=>{
 try {
   const {data} =await axios.get('https://panel.charismabroker.com/api/allCountModel')
   countModel.value=data
 }catch (e) {

 }finally {
   loadCount.value=false
 }
}
getAllCount()
const fetchData = async () => {
  try {
    loading.value = true
    const data = await UseFetchData(start.value, end.value, select_item.value)
    if(!data.success & data.code===422) {
    message.value="تاریخ انتخاب شده نامعتبر می باشد";
    showMessage.value=true
      return;
    }
    if (data.success) {
      rows.value = data.items
    }

  } catch (e) {
    message.value="خطای سرور";
    showMessage.value=true
  } finally {
    loading.value = false
  }
}
fetchData()
</script>
<template>
  <v-sheet theme="dark">
    <div class="max-h-screen md:hidden bg-[#14141e] pb-4">
      <div v-if="loading" class="w-screen backdrop-blur-md z-[100] h-screen fixed top-0 left-0 flex-center flex-col">
        <div class="w-64 h-64  rounded-xl  flex flex-col items-center">
          <div class="loader"></div>
        </div>
      </div>
      <header class="w-full z-[10] shadow-md sticky top-0 bg-[#161824] rounded-b-xl h-[65px] flex justify-between px-4 items-center">
        <button>
          <v-icon icon="fa text-slate-100 fa-bars"/>
        </button>
        <p class="flex gap-2 items-center">
        {{user.first_name}}
          <v-icon  icon="fa fa-user"/>
        </p>

      </header>
      <main dir="rtl" class="main p-3">
        <div class="bg-[#0d1019] font-bold p-2 rounded-md">
          <v-tabs selected-class="v-tab-item--selected" hide-slider
                  fixed-tabs class="bg-[#0d1019]"
                  v-model="tab">

            <v-tab value="1">
            <v-btn variant="text" block>  بورس کالا</v-btn>
            </v-tab>

            <v-tab value="2">
              <v-btn variant="text" block>  بورس انرژی</v-btn>
            </v-tab>
          </v-tabs>
        </div>
        <v-tabs-window dir="rtl" v-model="tab">
          <v-tabs-window-item value="1">
            <div dir="rtl" class="grid grid-cols-3 gap-3 p-3">

              <div v-for="item in buttons_item"
                   :key="item.id"
                   @click="select_item=item.id"
                   :class="[`border-${item.color}`,  `${item.id===select_item?`active`:''}` ]"
                   class="flex bg-[#212733] gap-4 border-b-4  rounded-xl px-3 py-4 flex-col
               justify-between items-center">
                <p :class="` text-${item.color}`" class=" rounded-md">
                  {{countModel[item.id-1]}}
                </p>
                <p class="text-xs">{{ item.name }}</p>
              </div>


            </div>
            <div class="mt-4 grid grid-cols-2 gap-2">
              <!--              <label for="">تاریخ شروع :</label>-->
              <DatePicker custom-input=".custom-input" v-model="start"/>
              <DatePicker custom-input=".custom-input_end" v-model="end"/>

              <v-text-field density="comfortable"
                            append-inner-icon="fa text-gray-400 fa-calendar-alt"
                            label="تاریخ شروع" variant="outlined" v-model="start"
                            class="custom-input text-sm"/>
              <v-text-field density="comfortable"

                            append-inner-icon="fa text-gray-400 text-xs fa-calendar-alt"
                            label="تاریخ پایان" variant="outlined" v-model="end"
                            class="custom-input_end text-sm"/>

            </div>
            <!--            <v-btn @click="fetchData" size="large" prepend-icon="fa fa-check" block :color="colors[select_item-1]" >-->
            <v-btn @click="fetchData"
                   size="large"
                   prepend-icon="fa fa-check" block color="green-darken-1">
              نمایش
              {{ buttons_item[select_item - 1]['name'] }}
            </v-btn>

            <div class="mt-2">
              <AgGrid :user="user" :start="start" :end="end" :template_column="template_column" :type="select_item" :rows="rows"/>
            </div>
          </v-tabs-window-item>


          <v-tabs-window-item value="2">
        <Energy/>
          </v-tabs-window-item>
        </v-tabs-window>

      </main>
    </div>

  <v-snackbar color="red" v-model="showMessage">
   <p class="text-center">
     {{message}}
   </p>
  </v-snackbar>
    <snack-bar/>
  </v-sheet>
</template>

